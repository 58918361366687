import { createContext, useState } from "react";

const AuthContext = createContext<{
  auth: { accessToken: string };
  setAuth: React.Dispatch<React.SetStateAction<{ accessToken: string }>>;
}>({
  auth: { accessToken: "" },
  setAuth: () => {},
});

interface Props {
  children: React.ReactElement;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState({ accessToken: "" });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
