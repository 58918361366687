import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useState, type FormEventHandler } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import axios from "../api/axios";
import Layout from "../components/Common/Layout";
import { dark } from "../constants/colors";
import useAuth from "../hooks/useAuth";

const LOGIN_URL = "/login";

const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
`;

const Image = styled.img`
  margin-top: 80px;
  width: 230px;
`;

const Title = styled.h2`
  font-size: 20px;
  color: ${dark.text};
  margin: 40px 0 20px;
`;

const StyledTextField = styled(TextField)`
  * {
    color: ${dark.text} !important;
    border-color: ${dark.text} !important;
  }

  label {
    color: ${dark.text} !important;
    background: ${dark.background};
  }
`;

const Button = styled.button`
  font-size: 18px;
  background-color: ${dark.convidiusGreen};
  padding: 13px 21px;
  border-radius: 5px;
  color: ${dark.text};
  cursor: pointer;
  transition: color 0.3s, background 0.3s;
  outline: none;
  border: none;
  width: 100%;
  margin-top: 16px;

  &:hover,
  &:focus {
    background: ${dark.elementsBackground};
  }
`;

export default function SignIn() {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { enqueueSnackbar } = useSnackbar();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    try {
      const response = await axios.post(LOGIN_URL, formData, {
        withCredentials: true,
      });
      const accessToken: string = response?.data?.access_token;
      setAuth({ accessToken });
      setUsername("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (err) {
      enqueueSnackbar("Anmeldung ist fehlgeschlagen", { variant: "error" });
    }
  };

  return (
    <Layout>
      <Container>
        <Image src="/images/logo.png" alt="logo" />
        <Title>ANMELDEN</Title>
        <form onSubmit={handleSubmit} noValidate>
          <StyledTextField
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email-Adresse"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <StyledTextField
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button type="submit">ANMELDEN</Button>
        </form>
      </Container>
    </Layout>
  );
}
