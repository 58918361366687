import { useCallback, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import RequireAuth from "./components/RequireAuth";
import { dark, light } from "./constants/colors";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";

function App() {
  const [theme, setTheme] = useState(dark);

  const toggleTheme = useCallback(() => {
    setTheme((prev) => (prev === dark ? light : dark));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<RequireAuth />}>
          <Route path="/" element={<Home toggleTheme={toggleTheme} />} />
        </Route>
        <Route path="/anmelden" element={<SignIn />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
