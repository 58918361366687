import { type FC, type ReactElement } from "react";
import styled from "styled-components";

const OuterContainer = styled.main`
  background-color: ${({ theme }) => theme.background};
  padding-bottom: 80px;
`;

interface Props {
  children: ReactElement;
}

const Layout: FC<Props> = ({ children }) => {
  return <OuterContainer>{children}</OuterContainer>;
};

export default Layout;
