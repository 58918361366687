import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import styled from "styled-components";

const Container = styled.div`
  background: ${({ theme }) => theme.sectionBackground};
  height: 300px;
  width: max(500px, calc(50% - 8px));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 8px 0;
  font-family: sans-serif;
  border-radius: 5px;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: 1070px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.text};
  font-size: 23px;
  align-self: center;
`;

const formatPercent = (value: number) => {
  return `${value.toFixed(0)}%`;
};

interface Props {
  title: string;
  getChartData: () => Array<{
    name: string;
    value: number;
    label: number;
  }>;
}

const chartColors = ["#2C8DD7", "#00C49F"];

const PieChartSection: React.FC<Props> = ({ getChartData, title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <ResponsiveContainer width={500}>
        <PieChart>
          <Pie
            data={getChartData()}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={40}
            label={({ name, label }) => {
              const a: string = name;
              return `${a}: ${formatPercent(label)}`;
            }}
          >
            {getChartData().map((_entry, index) => (
              <Cell
                key={index}
                fill={chartColors[index % chartColors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default PieChartSection;
