import { type MouseEventHandler } from "react";
import styled from "styled-components";

import { type SVGProps } from "./Icons/withSVG";

interface Props {
  Icon: React.FC<SVGProps>;
  iconProps?: SVGProps;
  className?: string;
  onClick?: MouseEventHandler;
  onFocus?: () => void;
  onBlur?: () => void;
  isSpan?: boolean;
}

const Button = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: rgba(140, 140, 140, 0.2);
  }
`;

const Span = styled.span`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: rgba(140, 140, 140, 0.2);
  }
`;

const IconButton: React.FC<Props> = ({
  Icon,
  iconProps,
  className,
  onClick,
  onFocus,
  onBlur,
  isSpan,
}) => {
  return isSpan ? (
    <Span
      className={className}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Icon {...iconProps} />
    </Span>
  ) : (
    <Button
      className={className}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Icon {...iconProps} />
    </Button>
  );
};

export default IconButton;
