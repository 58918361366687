import { Autocomplete as MUIAutocomplete } from "@mui/material";
import styled from "styled-components";

const Autocomplete = styled(MUIAutocomplete)`
  * {
    color: ${({ theme }) => theme.text} !important;
  }

  fieldset {
    border-color: ${({ theme }) => theme.text} !important;
  }
`;

export default Autocomplete;
