import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Fragment } from "react";
import styled, { useTheme } from "styled-components";

import { type dark } from "../../constants/colors";

const Container = styled.div`
  width: calc(50% - 8px);
  min-height: 416px;
  background: ${({ theme }) => theme.sectionBackground};
  border-radius: 5px;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: 1120px) {
    width: 100%;
    min-height: auto;
  }
`;

interface Props {
  tableData: Array<{
    name: string;
    projects: string | undefined;
    ordered: number | undefined;
    invoiced: number | undefined;
    month: string;
    capacity: number | undefined;
  }>;
}

const TableSection: React.FC<Props> = ({ tableData }) => {
  const theme = useTheme() as typeof dark;

  return (
    <Container>
      <TableContainer
        component={Paper}
        sx={{
          background: theme.sectionBackground,
          boxShadow: "none",
        }}
      >
        <Table aria-label="simple table">
          <TableBody>
            {tableData.map((row) => (
              <Fragment key={row.name}>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: theme.text }}
                  >
                    Mitarbeiter:
                  </TableCell>
                  <TableCell align="right" sx={{ color: theme.text }}>
                    {row.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.text }}
                    component="th"
                    scope="row"
                  >
                    Projekt:
                  </TableCell>
                  <TableCell sx={{ color: theme.text }} align="right">
                    {row.projects}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.text }}
                    component="th"
                    scope="row"
                  >
                    Bestellung (Std.):
                  </TableCell>
                  <TableCell sx={{ color: theme.text }} align="right">
                    {row.ordered}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.text }}
                    component="th"
                    scope="row"
                  >
                    Fakturiert (Std.):
                  </TableCell>
                  <TableCell sx={{ color: theme.text }} align="right">
                    {row.invoiced}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.text }}
                    component="th"
                    scope="row"
                  >
                    Monat:
                  </TableCell>
                  <TableCell sx={{ color: theme.text }} align="right">
                    {row.month}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.text, border: "none" }}
                    component="th"
                    scope="row"
                  >
                    Kapazität:
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.text, border: "none" }}
                    align="right"
                  >
                    {row.capacity}
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableSection;
