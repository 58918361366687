import Themes from "./themes";

export const dark = {
  topbar: "#24292e",
  convidiusGreen: "#69b42f",
  background: "#171C24",
  elementsBackground: "#455978",
  sectionBackground: "#222B36",
  text: "white",
  topNavBorder: "white",
  topNavBackground: "#171C24",
  modeIcon: "#FFC675",
  ID: Themes.DARK,
};

export const light = {
  topbar: "#24292e",
  convidiusGreen: "#69b42f",
  background: "#F3F4F9",
  elementsBackground: "#455978",
  sectionBackground: "#fff",
  text: "black",
  topNavBorder: "white",
  topNavBackground: "white",
  modeIcon: "#455978",
  ID: Themes.LIGHT,
};
