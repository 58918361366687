import { type AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ChangeEventHandler,
  type SyntheticEvent,
} from "react";

import styled from "styled-components";
import Layout from "../components/Common/Layout";
import BarChartSection from "../components/Home/BarChartSection";
import Inputs from "../components/Home/Inputs";
import PieChartSection from "../components/Home/PieChartSection";
import TableSection from "../components/Home/TableSection";
import TopBar from "../components/Home/TopBar";
import months, { monthsAbbreviations } from "../constants/months";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

const date = new Date();
const currentMonthIndex = date.getMonth();

const ALLE_MITARBEITER = "Alle Mitarbeiter";

interface EmployeeHours {
  capacity: number;
  invoiced: number;
  month: string;
  ordered: number;
  project: string;
  workloadIs: number;
  workloadShould: number;
}

type EmployeeHoursList = EmployeeHours[];

interface BarChartEntry {
  month: string;
  Bestellung: number;
  Fakturiert: number;
}

const findMonthIndex = (monthToFind: string) => {
  return months.findIndex((month) => month === monthToFind);
};

const Container = styled.div`
  max-width: 1300px;
  padding: 0 24px;
  margin: 0 auto;
`;

const PieChartsSection = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

const BarAndTableSection = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 16px;
`;

interface Props {
  toggleTheme: () => void;
}

const Home: React.FC<Props> = ({ toggleTheme }) => {
  const { enqueueSnackbar } = useSnackbar();

  const axiosPrivate = useAxiosPrivate();

  const [employeesNames, setEmployeesNames] = useState<string[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState(ALLE_MITARBEITER);

  const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);

  const [employeeHours, setEmployeeHours] = useState<EmployeeHoursList>([]);

  const [fileInputState, setFileInputState] = useState("");

  const isEmployeeHours = useMemo(() => {
    return employeeHours.length;
  }, [employeeHours]);

  const employeeHoursForSelectedMonth = useMemo(() => {
    if (!isEmployeeHours) return null;
    return employeeHours[selectedMonth];
  }, [employeeHours, isEmployeeHours, selectedMonth]);

  const getChartShouldData = useCallback(() => {
    const workloadShould = employeeHoursForSelectedMonth?.workloadShould;
    if (!workloadShould) {
      return [
        {
          name: "SOLL",
          value: 0,
          label: 0,
        },
        { name: "Verbleibend", value: 1, label: 100 },
      ];
    }

    const remaining = workloadShould >= 100 ? 0 : 100 - workloadShould;

    return [
      {
        name: "SOLL",
        value: workloadShould,
        label: workloadShould,
      },
      { name: "Verbleibend", value: remaining, label: 100 - workloadShould },
    ];
  }, [employeeHoursForSelectedMonth?.workloadShould]);

  const getChartIsData = useCallback(() => {
    const workloadIs = employeeHoursForSelectedMonth?.workloadIs;
    if (!workloadIs) {
      return [
        {
          name: "IST",
          value: 0,
          label: 0,
        },
        { name: "Verbleibend", value: 1, label: 100 },
      ];
    }
    const remaining = workloadIs >= 100 ? 0 : 100 - workloadIs;

    return [
      {
        name: "IST",
        value: workloadIs,
        label: workloadIs,
      },
      { name: "Verbleibend", value: remaining, label: 100 - workloadIs },
    ];
  }, [employeeHoursForSelectedMonth?.workloadIs]);

  const barChartData = useMemo(() => {
    if (!isEmployeeHours) return [];

    const result: BarChartEntry[] = [];
    monthsAbbreviations.forEach((month, index) => {
      const employeeHoursForMonth = employeeHours[index];
      const barchartEntry = {
        month,
        Bestellung: employeeHoursForMonth.ordered,
        Fakturiert: employeeHoursForMonth.invoiced,
      };

      result.push(barchartEntry);
    });

    return result;
  }, [employeeHours, isEmployeeHours]);

  const tableData = useMemo(
    () => [
      {
        name: selectedEmployee,
        projects: employeeHoursForSelectedMonth?.project,
        ordered: employeeHoursForSelectedMonth?.ordered,
        invoiced: employeeHoursForSelectedMonth?.invoiced,
        month: months[selectedMonth],
        capacity: employeeHoursForSelectedMonth?.capacity,
      },
    ],
    [employeeHoursForSelectedMonth, selectedEmployee, selectedMonth]
  );

  const handleCsvUploadOnChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const file = event.target.files?.[0];
    const inputValue = event.target.value;
    setFileInputState(inputValue);
    if (file == null) return;

    // make some kind of confirmation
    const formData = new FormData();
    formData.append("file", file);

    axiosPrivate
      .post("/employees/rewriteEmployeesData", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        enqueueSnackbar("Die Daten wurden erfolgreich übermittelt", {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar("Die Daten konnten nicht übermittelt werden", {
          variant: "error",
        });
      });
  };

  const fetchAllEmployees = useCallback(() => {
    axiosPrivate.get("/employees/all").then((res) => {
      setEmployeesNames([ALLE_MITARBEITER, ...res.data]);
    });
  }, [axiosPrivate]);

  const changeEmployee = useCallback(
    (_event: SyntheticEvent<Element, Event>, value: string | null) => {
      if (value) {
        setSelectedEmployee(value);
      }
    },
    []
  );

  const changeMonth = useCallback(
    (_event: SyntheticEvent<Element, Event>, value: string | null) => {
      if (value) {
        const monthIndex = findMonthIndex(value);
        setSelectedMonth(monthIndex);
      }
    },
    []
  );

  useEffect(() => {
    fetchAllEmployees();
  }, [fetchAllEmployees]);

  const fetchEmployeeHours = useCallback(() => {
    const requestParam =
      selectedEmployee === ALLE_MITARBEITER ? "all" : selectedEmployee;

    axiosPrivate
      .get(`/employees-hours/${requestParam}`)
      .then((res: AxiosResponse<EmployeeHoursList>) => {
        setEmployeeHours(res.data);
      });
  }, [axiosPrivate, selectedEmployee]);

  useEffect(() => {
    fetchEmployeeHours();
  }, [fetchEmployeeHours]);

  const inputsProps = {
    changeEmployee,
    changeMonth,
    selectedEmployee,
    employeesNames,
    selectedMonth,
    fileInputState,
    handleCsvUploadOnChange,
  };

  return (
    <Layout>
      <>
        <TopBar toggleTheme={toggleTheme} />
        <Container>
          <Inputs {...inputsProps} />
          <PieChartsSection>
            <PieChartSection
              getChartData={getChartShouldData}
              title="Auslastung SOLL"
            />
            <PieChartSection
              getChartData={getChartIsData}
              title="Auslastung IST"
            />
          </PieChartsSection>
          <BarAndTableSection>
            <BarChartSection barChartData={barChartData} />
            <TableSection tableData={tableData} />
          </BarAndTableSection>
        </Container>
      </>
    </Layout>
  );
};

export default Home;
