import { useCallback, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const RequireAuth: React.FC = () => {
  const [refreshTokenRequested, setRefreshTokenRequested] = useState(false);
  const { auth } = useAuth();
  const location = useLocation();

  const refresh = useRefreshToken();

  const tryToRefreshToken = useCallback(async () => {
    try {
      await refresh();
    } finally {
      setRefreshTokenRequested(true);
    }
  }, [refresh]);

  useEffect(() => {
    tryToRefreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (refreshTokenRequested) {
    return auth.accessToken ? (
      <Outlet />
    ) : (
      <Navigate to="/anmelden" state={{ from: location }} replace />
    );
  }

  return <div></div>;
};

export default RequireAuth;
