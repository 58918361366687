import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { type dark } from "../../constants/colors";
import Themes from "../../constants/themes";
import useLogout from "../../hooks/useLogout";
import IconButton from "../Common/IconButton";
import Logout from "../Common/Icons/Logout";
import Moon from "../Common/Icons/Moon";
import Sun from "../Common/Icons/Sun";

const Container = styled.div`
  background: ${({ theme }) => theme.topNavBackground};
  padding: 16px 48px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.topNavBorder};
  z-index: 3;
  position: relative;
`;

const Image = styled.img`
  width: 200px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

interface Props {
  toggleTheme: () => void;
}

const TopBar: React.FC<Props> = ({ toggleTheme }) => {
  const logout = useLogout();
  const theme = useTheme() as typeof dark;

  const ModeIcon = useMemo(
    () => (theme.ID === Themes.DARK ? Sun : Moon),
    [theme.ID]
  );

  return (
    <Container>
      <Image src="/images/logo.png" alt="logo" />
      <IconWrapper>
        <IconButton
          Icon={ModeIcon}
          onClick={toggleTheme}
          iconProps={{
            width: 20,
            fill: theme.modeIcon,
          }}
        />
        <IconButton
          onClick={logout}
          Icon={Logout}
          iconProps={{
            width: 25,
            fill: theme.elementsBackground,
          }}
        />
      </IconWrapper>
    </Container>
  );
};

export default TopBar;
