import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from "recharts";
import styled from "styled-components";

const Container = styled.div`
  background: ${({ theme }) => theme.sectionBackground};
  padding: 16px 40px 0 0;
  width: calc(50% - 8px);
  border-radius: 5px;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: 1120px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;

const StyledBarChart = styled(BarChart)`
  tspan {
    fill: ${({ theme }) => theme.text};
  }
`;

interface BarChartEntry {
  month: string;
  Bestellung: number;
  Fakturiert: number;
}

interface Props {
  barChartData: BarChartEntry[];
}

const BarChartSection: React.FC<Props> = ({ barChartData }) => {
  return (
    <Container>
      <StyledBarChart width={540} height={400} data={barChartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Legend />
        <Bar dataKey="Bestellung" fill="#2C8DD7" />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Legend />
        <Bar dataKey="Fakturiert" fill="#00C49F" />
      </StyledBarChart>
    </Container>
  );
};

export default BarChartSection;
