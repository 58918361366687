import { TextField } from "@mui/material";

import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ChangeEventHandler,
  type MouseEvent as ReactMouseEvent,
} from "react";
import styled, { useTheme } from "styled-components";
import { type dark } from "../../constants/colors";

import months from "../../constants/months";
import Autocomplete from "../Common/Autocomplete";
import IconButton from "../Common/IconButton";
import Search from "../Common/Icons/Search";
import Upload from "../Common/Icons/Upload";

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;

const InputsContainer = styled.div`
  position: absolute;
  top: -120px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.sectionBackground};
  padding: 24px 16px;
  transition: top 0.4s;
  width: 100%;
  border-radius: 5px;
  z-index: 2;

  &.active {
    top: 0;
  }
`;

const MonthInput = styled(Autocomplete)`
  margin-left: 16px;
`;

interface Props {
  changeEmployee: (...a: any) => void;
  selectedEmployee: string;
  employeesNames: string[];
  changeMonth: (...a: any) => void;
  selectedMonth: number;
  fileInputState: string;
  handleCsvUploadOnChange: ChangeEventHandler<HTMLInputElement>;
}

const acceptedFileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

const Inputs: React.FC<Props> = ({
  changeEmployee,
  selectedEmployee,
  employeesNames,
  changeMonth,
  selectedMonth,
  fileInputState,
  handleCsvUploadOnChange,
}) => {
  const theme = useTheme() as typeof dark;

  const containerRef = useRef<HTMLDivElement>(null);

  const [inputsVisible, setInputVisible] = useState(false);

  const showInputs = useCallback((event: ReactMouseEvent) => {
    event.stopPropagation();
    setInputVisible(true);
  }, []);

  const inputsContainerClassName = useMemo(
    () => (inputsVisible ? "active" : ""),
    [inputsVisible]
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        containerRef.current &&
        inputsVisible &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setInputVisible(false);
      }
    },
    [inputsVisible]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Container>
      <InputsContainer ref={containerRef} className={inputsContainerClassName}>
        <Autocomplete
          onChange={changeEmployee}
          value={selectedEmployee}
          disablePortal
          options={employeesNames}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Mitarbeiter" />
          )}
        />
        <MonthInput
          onChange={changeMonth}
          disablePortal
          options={months}
          value={months[selectedMonth]}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Monat" />}
        />
      </InputsContainer>
      <IconButton
        Icon={Search}
        iconProps={{
          width: 25,
          fill: theme.elementsBackground,
        }}
        onClick={showInputs}
      />
      <input
        type="file"
        accept={acceptedFileTypes.join(",")}
        style={{ display: "none" }}
        id="upload-excel"
        value={fileInputState}
        onChange={handleCsvUploadOnChange}
      />
      <label htmlFor="upload-excel">
        <IconButton
          isSpan
          Icon={Upload}
          iconProps={{
            width: 25,
            fill: theme.elementsBackground,
          }}
        />
      </label>
    </Container>
  );
};

export default Inputs;
